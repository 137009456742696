import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { CoreUserService } from 'app/core/user/user.service';
import { Account } from 'app/shared/models/account.model';
import { User } from 'app/shared/models/user.model';
import { environment } from 'environments/environment';
import { BehaviorSubject, catchError, Observable, of, switchMap, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {

    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(CoreUserService);

    private _account: BehaviorSubject<Account> = new BehaviorSubject(null);
    private _user: BehaviorSubject<User> = new BehaviorSubject(null);

    private _roleAs: string;

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    /**
     * Setter & getter for refresh token
     */
    set refreshToken(token: string) {
        localStorage.setItem('refreshToken', token);
    }

    get refreshToken(): string {
        return localStorage.getItem('refreshToken') ?? '';
    }

    // Method to get user role
    getRole() {
        this._roleAs = localStorage.getItem('ROLE');
        return this._roleAs;
    }

    get account$(): Observable<Account> {
        return this._account.asObservable();
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post(`${environment.api.endpoint}/auth/forgot-password`, { email });
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string, token: string): Observable<any> {
        return this._httpClient.post(`${environment.api.endpoint}/auth/reset-password?token=${token}`, { password });
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(`${environment.api.endpoint}/auth/login`, credentials).pipe(
            switchMap((response: any) => {

                const { tokens, user } = response;

                // Store the access token in the local storage
                this.accessToken = tokens.access.token;
                this.refreshToken = tokens.refresh.token;

                // Store the user on the user service
                this._userService.user = user;
                this._user.next(user);
                this._account.next(user.account);

                // this._userRole = user.role;
                localStorage.setItem('ROLE', user.role);

                // Set the authenticated flag to true
                this._authenticated = true;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient.post(`${environment.api.endpoint}/auth/refresh-tokens`, {
            refreshToken: this.refreshToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // console.log(response);

                if (!response) {
                    return of(false);
                }

                const { tokens, user } = response;

                // Store the access token in the local storage
                this.accessToken = tokens.access.token;
                this.refreshToken = tokens.refresh.token;

                // Store the user on the user service
                this._userService.user = user;
                this._user.next(user);
                console.log(user.account);
                this._account.next(user.account);

                // Set the authenticated flag to true
                this._authenticated = true;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('ROLE');

        this._userService.user = null;

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post(`${environment.api.endpoint}/auth/register`, user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    sendVerificationEmail(): Observable<any> {
        return this._httpClient.post(`${environment.api.endpoint}/auth/send-verification-email`, null);
    }



    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
