import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, map, Observable, ReplaySubject, tap } from 'rxjs';
// import { User } from 'app/shared/models/user.model';
import { environment } from 'environments/environment';
import { User } from 'app/shared/models/user.model';
import { Account } from 'app/shared/models/account.model';

@Injectable({
    providedIn: 'root'
})
export class CoreUserService {

    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    private _account: BehaviorSubject<Account> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    get account$(): Observable<Account> {
        return this._account.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    current(): Observable<User> {
        return this.user$;
    }


    /**
     * Get the current logged in user data
     */
    get(id: string): Observable<User> {
        return this._httpClient.get<User>(`${environment.api}/users/${id}`).pipe(
            tap((user) => {
                this._user.next(user);
            })
        );
    }

    getAll(): Observable<any> {
        const filter = {
            page: 1,
            limit: 99999,
        };
        let params = new HttpParams();
        const filterKeys = Object.keys(filter);
        for (const key of filterKeys) {
            if (filter[key] != null) {
                params = params.append(key, filter[key]);
            }
        }

        return this._httpClient.get(`${environment.api.endpoint}/users`, { params });
    }

    add(user: User): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.api.endpoint}/users`, user).subscribe((response) => {
                resolve(response);
            }, reject);
        });
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(userId: string, user: User): Observable<any> {
        return this._httpClient.patch<User>(`${environment.api.endpoint}/users/${userId}`, user).pipe(
            tap((response) => {
                this._user.next(response);
            })
        );
    }

    updateStatus(user: User): Observable<any> {
        return this._httpClient.patch(`${environment.api.endpoint}/users/status/${user.id}`, { status: user.status }).pipe(
            tap((response) => {
                this._user.next(response);
            })
        );
    }

    updateSetting(userId: string, setting: any): Observable<any> {
        return this._httpClient.patch(`${environment.api.endpoint}/users/setting/${userId}`, setting).pipe(
            tap((response) => {
                this._user.next(response);
            })
        );
    }

    // In Auth Service we have resetPassword - which is the front facing reset
    // This is where super admins and users can update their own passwords.
    updatePassword(userId: string, data: any): Observable<any> {
        return this._httpClient.patch(`${environment.api.endpoint}/users/update-password/${userId}`, data);
    }

    resendVerification(userId: string): Observable<any> {
        return this._httpClient.get(`${environment.api.endpoint}/users/reverify/${userId}`);
    }
}
