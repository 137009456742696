<div class="relative flex items-center justify-center overflow-hidden ring-primary-700"
    [ngStyle]="{'width.px': width, 'height.px': height}"
    [ngClass]="{'rounded-full ring-4': rounded, 'rounded-none ring-1': !rounded}">
    <ng-container *ngIf="edit">
        <div class="absolute inset-0 bg-black bg-opacity-0 z-10"></div>
        <div class="absolute inset-0 flex items-center justify-center z-20 bg-black transition-opacity duration-500"
            [ngClass]="{'hover:bg-opacity-50 opacity-0 hover:opacity-100': !isLoading && !isDeleting}">
            <ng-container *ngIf="!isLoading && !isDeleting">
                <div *ngIf="!previewImage">
                    <label (click)="openFileSelection()"
                        class="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer ring-1 ring-white bg-slate-800"
                        for="avatar-file-input" matRipple>
                        <mat-icon class="text-white" [svgIcon]="'heroicons_outline:camera'"></mat-icon>
                    </label>
                </div>
                <input type="file" #fileInput accept=".jpg, .jpeg, .png" (change)="handleFileInput($event.target.files)"
                    style="display: none;">
                <div *ngIf="previewImage">
                    <button mat-icon-button (click)="deleteImage()" class="ring-1 ring-white bg-slate-800">
                        <mat-icon class="text-white" [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="isLoading || isDeleting">
                <div>
                    <mat-icon
                        class="z-9999 animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 dark:border-gray-200"
                        svgIcon="heroicons_outline:cog-8-tooth">
                    </mat-icon>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <img class="object-cover w-full h-full" [appDynamicImgSrc]="previewImage" *ngIf="previewImage"
        [ngClass]="{'grayscale': isLoading || isDeleting}">
    <div class="flex items-center justify-center w-full h-full rounded overflow-hidden uppercase text-8xl font-bold leading-none bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
        *ngIf="!previewImage && !isLoading && !isDeleting">?
    </div>
</div>