import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { environment } from 'environments/environment';
import { MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { DynamicImgSrcDirective } from 'app/shared/directives/dynamic-img-src.directive';
import { AwsS3Service } from 'app/shared/services/aws-s3.service';
import { map } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'logo-avatar',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgStyle,
    MatButtonModule,
    MatIconModule,
    MatRippleModule,
    DynamicImgSrcDirective
  ],
  templateUrl: './logo-avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoAvatarComponent implements OnChanges {

  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() image: string;
  @Input() edit: boolean = true;
  @Input() rounded: boolean = true;
  @Input() width: number = 128;
  @Input() height: number = 128;
  @Output() uploaded: EventEmitter<any> = new EventEmitter();
  @Output() deleted: EventEmitter<any> = new EventEmitter();

  previewImage: string | ArrayBuffer | null = null;

  selectedImage: any = null;
  imagePreview: string | ArrayBuffer;

  isLoading: boolean = false;
  isDeleting: boolean = false;
  isError: boolean = false;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _awsService: AwsS3Service,
    private _toastrService: ToastrService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('image')) {
      this.previewImage = this.image;
    }
  }

  openFileSelection() {
    this.fileInput.nativeElement.click();
  }

  handleFileInput(files: FileList) {

    const selectedFile = files.item(0);

    if (selectedFile && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png')) {
      if (selectedFile.size <= 3 * 1024 * 1024) { // Limit 3MB

        this.isLoading = true;
        this._changeDetectorRef.markForCheck();

        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
          this._changeDetectorRef.markForCheck();
        };
        reader.readAsDataURL(selectedFile);

        this.uploadImage(selectedFile);

      } else {
        alert('File size should be less than 512KB');
      }
    } else {
      alert('Only JPEG or PNG files are allowed');
    }
  }

  uploadImage(file: File) {

    this.isError = false;
    this.isLoading = true;
    this._changeDetectorRef.markForCheck();

    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('path', 'public/images/agents');
    formData.append('file', file);

    this._awsService.upload(formData)
      .pipe(
        map((event: any) => {
          console.log(event);
          if (event instanceof HttpResponse && event.body !== null && event.body !== undefined) {
            return event;
          }
        })
      )
      .subscribe((res: HttpResponse<any>) => {
        console.log(res);
        if (res) {
          console.log('here!');
          this._toastrService.success('Agent avatar uploaded');
          this.uploaded.emit(res.body);
          this.isLoading = false;
        }
      }, (error) => {
        console.log(error);
        this.isLoading = false;
        this._toastrService.error(error.msg, 'Upload error');
      });
  }

  deleteImage(): void {

    if (!this.image) {
      // this._toastrService.error('No image defined');
      return;
    }

    this.deleted.emit(this.image);
    this.previewImage = null;

    // this.isDeleting = true;
    // this.isError = false;
    // this._changeDetectorRef.markForCheck();

    // const imageFilename = this.image.split('/');
    // const filename = imageFilename[imageFilename.length - 1];

    // this._uploadService.deleteImage(filename).subscribe((res) => {
    //   this._toastrService.success('Image deleted');
    //   this.isDeleting = false;
    //   this.deleted.emit(res);
    // }, (error) => {
    //   console.log('Delete image error', error);
    //   this._toastrService.error(error.msg, 'Image delete error');
    //   this.isDeleting = false;
    //   this.isError = true;
    //   this._changeDetectorRef.markForCheck();
    // })

    // this.previewImage = null;
    // this.deleted.emit(null);
    // this._changeDetectorRef.markForCheck();
  }

  getImageUrl(url: string): string {
    return `${environment.media.url}/${url}`;
  }

}
