<div class="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-900 border-b">
    <div class="flex items-center">
        <mat-icon svgIcon="mat_outline:close" class="cursor-pointer icon-size-5" (click)="close.emit()"></mat-icon>
        <div class="flex flex-col ml-3">
            <h2 class="text-lg font-semibold">{{title}}</h2>
            <div class="mt-0.5 text-secondary text-sm" [innerHtml]="subtitle"></div>
        </div>
    </div>
    <ng-container *ngIf="showSave">
        <ng-container *ngIf="isMobile else desktopButton;">
            <button mat-icon-button [color]="'primary'" class="bg-card ring-1" (click)="saveClick()" [tabIndex]="9999" [disabled]="saveBtnDisabled">
                <mat-icon [svgIcon]="saveBtnIcon" class="icon-size-5" *ngIf="!loading"></mat-icon>
                <mat-icon svgIcon="heroicons_outline:cog-8-tooth" class="icon-size-5 animate-spin-slow"
                    *ngIf="loading"></mat-icon>
            </button>
        </ng-container>
        <ng-template #desktopButton>
            <button mat-flat-button [color]="'primary'" (click)="saveClick()" [tabIndex]="9999" [disabled]="saveBtnDisabled">
                <mat-icon [svgIcon]="saveBtnIcon" class="icon-size-5" *ngIf="!loading"></mat-icon>
                <mat-icon svgIcon="heroicons_outline:cog-8-tooth" class="icon-size-5 animate-spin-slow"
                    *ngIf="loading"></mat-icon>
                <span class="ml-2">{{saveBtnText}}</span>
            </button>
        </ng-template>
    </ng-container>
</div>