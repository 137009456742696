<form [formGroup]="passwordForm" name="passwordForm" class="bg-card p-4">

    <div class="flex flex-col">

        <div class="text-xl font-semibold pt-0 pb-4" *ngIf="data">{{data.title}}</div>

        <!-- Password field -->
        <mat-form-field class="w-full">
            <mat-label>Password</mat-label>
            <input id="password" matInput type="password" [formControlName]="'password'" #passwordField>
            <button mat-icon-button type="button"
                (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                matSuffix>
                <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'password'"
                    [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'text'"
                    [svgIcon]="'heroicons_solid:eye-slash'"></mat-icon>
            </button>
            <mat-error>
                Password is required
            </mat-error>
        </mat-form-field>

        <!-- Password confirm field -->
        <mat-form-field class="w-full">
            <mat-label>Password (Confirm)</mat-label>
            <input id="password-confirm" matInput type="password" [formControlName]="'passwordConfirm'"
                #passwordConfirmField>
            <button mat-icon-button type="button"
                (click)="passwordConfirmField.type === 'password' ? passwordConfirmField.type = 'text' : passwordConfirmField.type = 'password'"
                matSuffix>
                <mat-icon class="icon-size-5" *ngIf="passwordConfirmField.type === 'password'"
                    [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                <mat-icon class="icon-size-5" *ngIf="passwordConfirmField.type === 'text'"
                    [svgIcon]="'heroicons_solid:eye-slash'"></mat-icon>
            </button>
            <mat-error *ngIf="passwordForm.get('passwordConfirm').hasError('required')">
                Password confirmation is required
            </mat-error>
            <mat-error *ngIf="passwordForm.get('passwordConfirm').hasError('mustMatch')">
                Passwords must match
            </mat-error>
        </mat-form-field>

        <div class="flex flex-row items-center justify-between gap-4">
            <!-- Submit button -->
            <button class="fuse-mat-button-large w-full mt-3" mat-flat-button [color]="'accent'" (click)="close()">
                <span>Cancel</span>
            </button>

            <button class="fuse-mat-button-large w-full mt-3" mat-flat-button [color]="'primary'"
                [disabled]="passwordForm.invalid" (click)="submit()">
                <span>Submit</span>
            </button>
        </div>

    </div>

</form>
