/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard'
    },
    {
        id: 'bookings',
        title: 'Bookings',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/bookings'
    },
    {
        id: 'crm',
        title: 'CRM',
        // subtitle: 'People & Person management',
        type: 'collapsable',
        icon: 'heroicons_outline:users',
        children: [
            {
                id: 'crm.agents',
                title: 'Agents',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/crm/agents'
            },
            {
                id: 'crm.customers',
                title: 'Customers',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/crm/customers'
            },
            {
                id: 'crm.employees',
                title: 'Employees',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/crm/employees'
            },
        ]
    },
    {
        id: 'diving',
        title: 'Diving',
        // subtitle: 'Trip Inventory management',
        icon: 'heroicons_outline:clipboard-document-list',
        type: 'collapsable',
        children: [
            {
                id: 'diving.boats',
                title: 'Boats',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/diving/boats'
            },
            {
                id: 'diving.courses',
                title: 'Courses',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/diving/courses'
            },
            {
                id: 'diving.divesites',
                title: 'Dive sites',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/diving/dive-sites'
            },
            {
                id: 'diving.locations',
                title: 'Locations',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/diving/locations'
            },
            {
                id: 'diving.trips',
                title: 'Trips',
                type: 'basic',
                link: '/diving/trips'
            }
        ]
    },
    {
        id: 'ecommerce',
        title: 'ECommerce',
        type: 'collapsable',
        icon: 'heroicons_outline:shopping-cart',
        children: [
            {
                id: 'ecommerce.orders',
                title: 'Orders',
                type: 'basic',
                link: '/ecommerce/orders'
            },
            {
                id: 'ecommerce.products',
                title: 'Products',
                type: 'basic',
                link: '/ecommerce/products'
            },
            {
                id: 'ecommerce.options',
                title: 'Options',
                type: 'basic',
                link: '/ecommerce/options'
            },
            {
                id: 'ecommerce.categories',
                title: 'Categories',
                type: 'basic',
                link: '/ecommerce/categories'
            },
            {
                id: 'ecommerce.vendors',
                title: 'Vendors',
                type: 'basic',
                link: '/ecommerce/vendors'
            }
        ]
    },
    {
        id: 'accounting',
        title: 'Accounting',
        // subtitle: 'Quotes, Invoices & Transactions',
        type: 'collapsable',
        icon: 'mat_outline:account_balance',
        children: [
            {
                id: 'accounting.quotes',
                title: 'Quotes',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/accounting/quotes'
            },
            {
                id: 'accounting.invoices',
                title: 'Invoices',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/accounting/invoices'
            },
            {
                id: 'accounting.transactions',
                title: 'Transactions',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/accounting/transactions'
            },
        ]
    },
    {
        id: 'divider-1',
        type: 'divider'
    },
    {
        id: 'account',
        title: 'Account',
        type: 'collapsable',
        icon: 'heroicons_outline:cloud',
        children: [
            {
                id: 'account.settings',
                title: 'Settings',
                type: 'basic',
                link: '/account/profile'
            },
            {
                id: 'account.users',
                title: 'Users',
                type: 'basic',
                link: '/account/users'
            },
        ]
    },
    {
        id: 'file-manager',
        title: 'File manager',
        type: 'basic',
        icon: 'heroicons_outline:cloud',
        link: '/file-manager'
    },
];

// USER NAVIGATION
export const userNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard'
    },
    {
        id: 'crm',
        title: 'CRM',
        // subtitle: 'People & Person management',
        type: 'collapsable',
        icon: 'heroicons_outline:users',
        children: [
            {
                id: 'crm.agents',
                title: 'Agents',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/crm/agents'
            },
            {
                id: 'crm.customers',
                title: 'Customers',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/crm/customers'
            },
            {
                id: 'crm.employees',
                title: 'Employees',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/crm/employees'
            },
        ]
    },
    {
        id: 'diving',
        title: 'Diving',
        // subtitle: 'Trip Inventory management',
        icon: 'heroicons_outline:clipboard-document-list',
        type: 'collapsable',
        children: [
            {
                id: 'diving.boats',
                title: 'Boats',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/diving/boats'
            },
            {
                id: 'diving.courses',
                title: 'Courses',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/diving/courses'
            },
            {
                id: 'diving.divesites',
                title: 'Dive sites',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/diving/dive-sites'
            },
            {
                id: 'diving.locations',
                title: 'Locations',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/diving/locations'
            },
            {
                id: 'diving.trips',
                title: 'Trips',
                type: 'basic',
                link: '/diving/trips'
            }
        ]
    },
    {
        id: 'ecommerce',
        title: 'ECommerce',
        type: 'collapsable',
        icon: 'heroicons_outline:shopping-cart',
        children: [
            {
                id: 'ecommerce.orders',
                title: 'Orders',
                type: 'basic',
                link: '/ecommerce/orders'
            },
            {
                id: 'ecommerce.products',
                title: 'Products',
                type: 'basic',
                link: '/ecommerce/products'
            },
            {
                id: 'ecommerce.options',
                title: 'Options',
                type: 'basic',
                link: '/ecommerce/options'
            },
            {
                id: 'ecommerce.categories',
                title: 'Categories',
                type: 'basic',
                link: '/ecommerce/categories'
            },
            {
                id: 'ecommerce.vendors',
                title: 'Vendors',
                type: 'basic',
                link: '/ecommerce/vendors'
            }
        ]
    },
    {
        id: 'accounting',
        title: 'Accounting',
        // subtitle: 'Quotes, Invoices & Transactions',
        type: 'collapsable',
        icon: 'mat_outline:account_balance',
        children: [
            {
                id: 'accounting.quotes',
                title: 'Quotes',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/accounting/quotes'
            },
            {
                id: 'accounting.invoices',
                title: 'Invoices',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/accounting/invoices'
            },
            {
                id: 'accounting.transactions',
                title: 'Transactions',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/accounting/transactions'
            },
        ]
    },
    {
        id: 'divider-1',
        type: 'divider'
    },
    {
        id: 'account',
        title: 'Account',
        type: 'collapsable',
        icon: 'mat_outline:account_box',
        children: [
            {
                id: 'account.profile',
                title: 'Profile',
                type: 'basic',
                link: '/account/profile'
            },
            // {
            //     id: 'account.settings',
            //     title: 'Settings',
            //     type: 'basic',
            //     link: '/account/settings'
            // },
            {
                id: 'account.users',
                title: 'Users',
                type: 'basic',
                link: '/account/users'
            },
        ]
    },
    {
        id: 'file-manager',
        title: 'File manager',
        type: 'basic',
        icon: 'heroicons_outline:cloud',
        link: '/file-manager'
    }
];

export const guestNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard'
    },
    {
        id: 'trips',
        title: 'Trips',
        type: 'basic',
        icon: 'heroicons_outline:view-boards',
        link: '/trips'
    }
];

export const agentNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard'
    },
    {
        id: 'crm',
        title: 'CRM',
        // subtitle: 'People & Person management',
        type: 'collapsable',
        icon: 'heroicons_outline:users',
        children: [
            {
                id: 'crm.agents',
                title: 'Agents',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/crm/agents'
            },
            {
                id: 'crm.customers',
                title: 'Customers',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/crm/customers'
            },
            {
                id: 'crm.employees',
                title: 'Employees',
                type: 'basic',
                // icon: 'heroicons_outline:academic-cap',
                link: '/crm/employees'
            },
        ]
    },
    // {
    //     id: 'diving',
    //     title: 'Diving',
    //     // subtitle: 'Trip Inventory management',
    //     icon: 'heroicons_outline:clipboard-document-list',
    //     type: 'collapsable',
    //     children: [
    //         {
    //             id: 'diving.boats',
    //             title: 'Boats',
    //             type: 'basic',
    //             // icon: 'heroicons_outline:academic-cap',
    //             link: '/diving/boats'
    //         },
    //         {
    //             id: 'diving.courses',
    //             title: 'Courses',
    //             type: 'basic',
    //             // icon: 'heroicons_outline:academic-cap',
    //             link: '/diving/courses'
    //         },
    //         {
    //             id: 'diving.divesites',
    //             title: 'Dive sites',
    //             type: 'basic',
    //             // icon: 'heroicons_outline:academic-cap',
    //             link: '/diving/dive-sites'
    //         },
    //         {
    //             id: 'diving.equipment',
    //             title: 'Equipment',
    //             type: 'basic',
    //             // icon: 'heroicons_outline:shopping-cart',
    //             link: '/diving/equipment'
    //         },
    //         {
    //             id: 'diving.locations',
    //             title: 'Locations',
    //             type: 'basic',
    //             // icon: 'heroicons_outline:academic-cap',
    //             link: '/diving/locations'
    //         },
    //     ]
    // },
    {
        id: 'divider-1',
        type: 'divider'
    },
    {
        id: 'account',
        title: 'Account',
        type: 'collapsable',
        icon: 'mat_outline:account_box',
        children: [
            {
                id: 'account.profile',
                title: 'Profile',
                type: 'basic',
                link: '/account/profile'
            },
            // {
            //     id: 'account.settings',
            //     title: 'Settings',
            //     type: 'basic',
            //     link: '/account/settings'
            // },
            {
                id: 'account.users',
                title: 'Users',
                type: 'basic',
                link: '/account/users'
            },
        ]
    },
    {
        id: 'file-manager',
        title: 'File manager',
        type: 'basic',
        icon: 'heroicons_outline:cloud',
        link: '/file-manager'
    }
]

export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
