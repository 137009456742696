<dialog-header (close)="dialogRef.close()" (save)="save()" [title]="'Profile'"></dialog-header>

<form [formGroup]="userForm" name="userForm" class="flex flex-col bg-card overflow-hidden">

    <div class="flex flex-row items-center place-content-between bg-gray-50 dark:bg-gray-700 p-4 border-b">

        <div class="flex flex-col">
            <div class="text-lg font-medium" *ngIf="userForm.get('firstname').value && userForm.get('lastname').value">
                {{userForm.get('firstname').value}} {{userForm.get('lastname').value}}</div>
            <div class="text-lg font-medium"
                *ngIf="!userForm.get('firstname').value && !userForm.get('lastname').value && data.mode === 'add'">
                Add
                User</div>

            <div class="text-lg font-medium"
                *ngIf="!userForm.get('firstname').value && !userForm.get('lastname').value && data.mode !== 'edit'">
                Your full name</div>

            <div class="text-secondary text-sm mt-1 truncate">{{userForm.get('email').value}}</div>
        </div>

        <!-- EDIT AVATAR -->
        <div class="">
            <logo-avatar [image]="userForm.get('avatar').value" [width]="80" [height]="80"
                (uploaded)="userForm.get('avatar').patchValue($event.imageUrl)"
                (deleted)="userForm.get('avatar').patchValue(null)"></logo-avatar>
        </div>
        <!-- // EDIT AVATAR -->
    </div>
    <div class="flex flex-col p-4">
        <div class="flex items-center gap-2">
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>First name</mat-label>
                <input matInput formControlName="firstname" name="firstname" required>
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Last name</mat-label>
                <input matInput formControlName="lastname" name="lastname" required>
            </mat-form-field>
        </div>

        <mat-form-field class="fuse-mat-dense flex-auto">
            <mat-label>Email Address</mat-label>
            <input matInput formControlName="email" name="email" required>
        </mat-form-field>

        <mat-form-field class="fuse-mat-dense flex-auto">
            <mat-label>Mobile</mat-label>
            <input matInput formControlName="mobile" name="mobile">
        </mat-form-field>

        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'" *ngIf="data.user.role === 'admin'">
            <mat-label>Role</mat-label>
            <mat-select [panelClass]="'w-72 min-w-72 max-w-72 h-auto max-h-none'" formControlName="role"
                disableOptionCentering #roleSelect="matSelect">
                <mat-select-trigger class="text-md">
                    <span>Role:</span>
                    <span class="ml-1 font-medium">{{roleSelect.value | titlecase}}</span>
                </mat-select-trigger>
                <ng-container *ngFor="let role of roles">
                    <mat-option class="h-auto py-4 leading-none" [value]="role.value">
                        <div class="font-medium">{{role.label}}</div>
                        <div class="mt-1.5 text-sm whitespace-normal leading-normal text-secondary">
                            {{role.description}}</div>
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <button mat-flat-button aria-label="Reset password" (click)="resetPassword()" class="mt-2">
            <mat-icon [svgIcon]="'heroicons_outline:lock-closed'"></mat-icon>
            <span class="ml-2">Reset Password</span>
        </button>

        <ng-container *ngIf="data.mode === 'new'">

            <!-- Password field -->
            <mat-form-field class="w-full">
                <mat-label>Password</mat-label>
                <input id="password" matInput type="password" [formControlName]="'password'" #passwordField>
                <button mat-icon-button type="button"
                    (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                    matSuffix>
                    <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'password'"
                        [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                    <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'text'"
                        [svgIcon]="'heroicons_solid:eye-slash'"></mat-icon>
                </button>
                <mat-error>
                    Password is required
                </mat-error>
            </mat-form-field>

            <!-- Password confirm field -->
            <mat-form-field class="w-full">
                <mat-label>Password (Confirm)</mat-label>
                <input id="password-confirm" matInput type="password" [formControlName]="'passwordConfirm'"
                    #passwordConfirmField>
                <button mat-icon-button type="button"
                    (click)="passwordConfirmField.type === 'password' ? passwordConfirmField.type = 'text' : passwordConfirmField.type = 'password'"
                    matSuffix>
                    <mat-icon class="icon-size-5" *ngIf="passwordConfirmField.type === 'password'"
                        [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                    <mat-icon class="icon-size-5" *ngIf="passwordConfirmField.type === 'text'"
                        [svgIcon]="'heroicons_solid:eye-slash'"></mat-icon>
                </button>
                <mat-error *ngIf="userForm.get('passwordConfirm').hasError('required')">
                    Password confirmation is required
                </mat-error>
                <mat-error *ngIf="userForm.get('passwordConfirm').hasError('mustMatch')">
                    Passwords must match
                </mat-error>
            </mat-form-field>
        </ng-container>
    </div>

    <!-- <div class="flex items-center justify-between border-t px-4 py-3 bg-gray-50 dark:bg-gray-700">
        <button mat-button (click)="dialogRef.close()">
            Cancel
        </button>
        <button class="px-6 ml-3 min-w-25" mat-flat-button [color]="'primary'" [ngClass]="{'accent': loading$}"
            [disabled]="!userForm.valid" (click)="save()">
            <mat-icon class="icon-size-5 text-white animate-spin-slow" [svgIcon]="'heroicons_solid:cog'"
                *ngIf="loading$"></mat-icon>
            <mat-icon class="icon-size-5" [svgIcon]="'mat_outline:save'" *ngIf="!loading$"></mat-icon>
            <span class="ml-2">Save</span>
        </button>
    </div> -->
</form>

<!-- <dialog-footer></dialog-footer> -->