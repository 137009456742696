/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
    production: true,
    hmr: false,
    domain: 'https://booktodive.cortek-solutions.com',
    api: {
        endpoint: 'https://dt16obdy6j.execute-api.us-east-1.amazonaws.com/staging/v1', // endpoint: 'http://52.221.217.58:3000/v1',
        public: 'https://dt16obdy6j.execute-api.us-east-1.amazonaws.com/staging/v1/public',
        version: '0.1.2'
    },
    media: {
        url: 'https://s3.amazonaws.com/cdn.cortek-solutions.com',
        size: {
            list: '40x40',
            preview: '80xAUTO',
            logo: '300xAUTO',
            grid: '300xAUTO'
        }
    },
    aws: {
        bucket: 'cdn.cortek-solutions.com',
    },
    google: {
        maps: {
            apikey: 'AIzaSyAaA9vLBRsfbpehLDJFaNf3MiWDGRfy5Ok'
        }
    },
    version: '0.1.4'
};
