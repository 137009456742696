import { Directive, Input, ElementRef, Renderer2, OnDestroy, AfterViewInit } from '@angular/core';
import { environment } from 'environments/environment';

@Directive({
  standalone: true,
  selector: '[appDynamicImgSrc]',
})
export class DynamicImgSrcDirective implements AfterViewInit, OnDestroy {

  @Input() set appDynamicImgSrc(value: string) {
    this._imgSrc = `${environment.media.url}/${value}`;
    this.tryLoadImage();
  }

  private _imgSrc: string;
  private observer: IntersectionObserver;
  private placeholder = '/assets/images/loaders/puff.svg';

  constructor(private el: ElementRef, private renderer: Renderer2) {
    // Set initial placeholder image
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.placeholder);
  }

  ngAfterViewInit() {
    this.initLazyLoading();
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private initLazyLoading() {
    if ('IntersectionObserver' in window) {
      this.observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.loadImage();
            observer.disconnect();
          }
        });
      });
      this.observer.observe(this.el.nativeElement);
    } else {
      // Fallback for browsers that do not support IntersectionObserver
      this.loadImage();
    }
  }

  private loadImage() {
    this.renderer.setAttribute(this.el.nativeElement, 'src', this._imgSrc);

    // Handle the error event
    this.renderer.listen(this.el.nativeElement, 'error', () => {
      this.renderer.setAttribute(this.el.nativeElement, 'src', '/assets/images/loaders/error.svg');
    });
  }

  private tryLoadImage() {
    if (this.observer) {
      this.observer.disconnect();
    }
    this.initLazyLoading();
  }

}
