import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ToastrService } from 'ngx-toastr';
import { FuseValidators } from '@fuse/validators';
import { MatSelectModule } from '@angular/material/select';
import { LogoAvatarComponent } from '../logo-avatar/logo-avatar.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CoreUserService } from 'app/core/user/user.service';
import { MatOptionModule } from '@angular/material/core';
import { DialogHeaderComponent } from '../dialog-templates/dialog-header/dialog-header.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { User } from 'app/shared/models/user.model';
import { NgxPasswordGeneratorComponent } from 'app/shared/modules/ngx-password-generator/ngx-password-generator.component';

export interface Options {
    user: User;
    mode: string;
    isCurrentUser: boolean;
    isMobile: boolean;
}

@Component({
    standalone: true,
    selector: 'user-profile',
    templateUrl: './profile.component.html',
    encapsulation: ViewEncapsulation.None,
    imports: [
        NgClass,
        NgIf,
        NgFor,
        TitleCasePipe,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        LogoAvatarComponent,
        DialogHeaderComponent,
    ],
})
export class UserProfileDialogComponent {

    userForm: FormGroup;
    roles: any[];
    loading$ = false;

    constructor(
        public dialogRef: MatDialogRef<UserProfileDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Options,
        private _matDialog: MatDialog,
        private _userService: CoreUserService,
        private _coreUserService: CoreUserService,
        private _toastrService: ToastrService,
        private _formBuilder: FormBuilder,
        private _breakpointObserver: BreakpointObserver,
    ) {
        this.roles = [
            {
                label: 'Agent',
                value: 'agent',
                description: 'Make bookings and view trips.',
            },
            {
                label: 'User',
                value: 'user',
                description: 'Can read, clone, and push to this repository. Can also manage issues and pull requests.',
            },
            {
                label: 'Admin',
                value: 'admin',
                description: 'Can read, clone, and push to this repository. Can also manage issues, pull requests, and repository settings, including adding collaborators.'
            }
        ];
    }

    ngOnInit(): void {
        //  console.log(this.data.user);

        // if (this.data.user.role === 'admin') {
        //     this.roles.push(
        //         {
        //             label: 'Admin',
        //             value: 'admin',
        //             description: 'Manage Account, Users, Devices, Projects and Dashboards',
        //         },
        //     )
        // }

        this.userForm = this._createUserForm();
        this.userForm.patchValue(this.data.user);

        if (this.data.mode === 'new') {
            this.userForm.addControl('account', new FormControl(this.data.user.account, Validators.required));
            this.userForm.addControl('password', new FormControl('', Validators.required));
            this.userForm.addControl('passwordConfirm', new FormControl('', {
                validators: FuseValidators.mustMatch('password', 'passwordConfirm')
            }));
        }

        if (this.data.user.role === 'super') {
            this.userForm.removeControl('role');
        }
    }

    save(): void {
        if (this.data.mode === 'new') {
            this.createUser();
        } else {
            this.updateUser();
        }
    }

    createUser(): void {
        this.loading$ = true;
        const user = this.userForm.getRawValue();
        delete user.passwordConfirm;
        // console.log(user);

        this._userService.add(user).then((res) => {
            //  console.log(res);
            this.loading$ = false;
            this._toastrService.success(`User ${user.firstname} ${user.lastname} created!`);
            this.dialogRef.close(res);
        }).catch((error) => {
            console.log(error);
            this.loading$ = false;
            this._toastrService.error(error.msg);
        });

    }

    updateUser(): void {
        this.loading$ = true;
        const user: User = this.userForm.getRawValue();

        this._userService.update(this.data.user.id, user).subscribe(() => {
            this.loading$ = false;
            this._toastrService.success(`User profile updated`);

            if (this.data.isCurrentUser) {
                this._coreUserService.user = user;
            }

            this.dialogRef.close(true);

        }, (error) => {
            console.log(error);
            this.loading$ = false;

            if (error.status === 403) {
                this._toastrService.error('Admin permission required');
            } else {
                this._toastrService.error(error.msg);
            }
        });
    }

    countryStateCityChanged($event: any): void {
        this.userForm.get('location').patchValue($event);
        this.userForm.markAsDirty();
    }


    resetPassword(): void {

        const isMobile = this._breakpointObserver.isMatched('(max-width: 767px)');

        const resetDialog = this._matDialog.open(NgxPasswordGeneratorComponent, {
            disableClose: false,
            width: isMobile ? '100%' : '380px',
            height: isMobile ? '100%' : 'auto',
            maxWidth: isMobile ? '100vw' : '80vw',
            maxHeight: isMobile ? '100vh' : '80vh',
            panelClass: isMobile ? 'dialog-no-padding' : 'dialog-no-padding',
            data: {
                user: this.data.user,
                isMobile,
                isCurrentUser: false
            }
        });

        // resetDialog.afterClosed().subscribe((res) => {
        //     if (res) {
        //         this._router.navigate(['/sign-out']);
        //     }
        // })
    }

    private _createUserForm(): FormGroup {
        return this._formBuilder.group({
            firstname: ['', [Validators.required]],
            lastname: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            mobile: [''],
            avatar: [''],
            role: ['user', Validators.required]
        });
    }

}
