import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseValidators } from '@fuse/validators';

@Component({
    selector: 'ngx-password-generator',
    templateUrl: './ngx-password-generator.component.html'
})
export class NgxPasswordGeneratorComponent implements OnInit {

    passwordForm: FormGroup;

    constructor(
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<NgxPasswordGeneratorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        this.passwordForm = this._formBuilder.group({
            password: ['', Validators.required],
            passwordConfirm: ['', Validators.required]
        },
            {
                validators: FuseValidators.mustMatch('password', 'passwordConfirm')
            }
        );
    }

    submit(): void {
        this.dialogRef.close(this.passwordForm.value);
    }

    close(): void {
        this.dialogRef.close(false);
    }

}
