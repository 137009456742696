import { HttpRequest, HttpEvent, HttpHandlerFn, HttpInterceptorFn } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// import { LoggerService } from './logger.service';

export const errorInterceptor: HttpInterceptorFn = (request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
        return next(request).pipe(
            catchError((err) => {
                if (err.status === 401) {
                    // auto logout if 401 response returned from api
                 //  this._router.navigate(['/auth/login']);
                    // this._authService.logout();
                    // location.reload(true);
                }
                if (err.status === 403) {
                    // alert('Authentication error - you are not authorised to carry out this action');
                }
                console.log(err);
                const error = { ok: err.ok, msg: err.error.message, status: err.status, statusText: err.statusText };
                console.log(error);
                return throwError(error);
            })
        );
    }

