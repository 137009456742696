import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboards/project'
    { path: '', pathMatch: 'full', redirectTo: 'bookings' },

    // Redirect signed-in user to the '/dashboards/project'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'bookings' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes') },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes') },
            { path: 'verify-email', loadChildren: () => import('app/modules/auth/verify-email/verify-email.routes') }
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') }
        ]
    },

    // Landing routes
    // {
    //     path: '',
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children: [
    //         {path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes')},
    //     ]
    // },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [

            {
                path: 'bookings',
                loadChildren: () => import('app/modules/admin/bookings/bookings.routes'),
                data: {
                    roles: ['super', 'admin', 'user', 'agent']
                }
            },

            {
                path: 'crm',
                loadChildren: () => import('app/modules/admin/crm/crm.routes'),
                data: {
                    roles: ['super', 'admin', 'user']
                }
            },

            { path: 'dashboard', loadChildren: () => import('app/modules/admin/dashboard/dashboard.module').then(m => m.DashboarDModule) },

            // Diving - Boats | Equipment | Locations | Dive Sites
            {
                path: 'diving',
                loadChildren: () => import('app/modules/admin/diving/diving.routes'),
                data: {
                    roles: ['super', 'admin', 'user']
                }
            },

            // LOADED DYNAMICALLY BASED ON USER SETTINGS
            // Accounting & Ecommerce
            // {
            //     path: 'accounting',
            //     loadChildren: () => import('app/modules/admin/accounting/accounting.module').then((m => m.AccountingModule))
            // },

            // {
            //     path: 'ecommerce', loadChildren: () => import('app/modules/admin/ecommerce/ecommerce.module').then(m => m.EcommerceModule),
            //     data: { title: 'Ecommerce' }
            // },

            // Media Manager
            {
                path: 'file-manager', loadChildren: () => import('app/modules/admin/media-manager/media-manager.routes'),
                data: {
                    title: 'Media Manager',
                    roles: ['super', 'admin']
                }
            },

            {
                path: 'transactions', loadChildren: () => import('app/modules/admin/accounting/transactions/transactions.module').then(m => m.TransactionsModule),
                data: {
                    title: 'Transactions',
                    roles: ['super', 'admin']
                }
            },

            // {
            //     path: 'settings',
            //     loadChildren: () => import('app/modules/admin/settings/settings.module').then(m => m.SettingsModule),
            //     data: {
            //         roles: ['admin', 'user'],
            //         title: 'Settings'
            //     }
            // },

            {
                path: 'account',
                loadChildren: () => import('app/modules/admin/account/account.routes'),
                data: {
                    roles: ['super', 'admin', 'user']
                }
            },

            {
                path: 'users',
                loadChildren: () => import('app/modules/admin/users/users.routes'),
                data: {
                    title: 'Users',
                    roles: ['super']
                }
            },

            // {
            //     path: 'user',
            //     loadChildren: () => import('app/modules/admin/user/user.routes'),
            // },

            // Maintenance
            { path: 'maintenance', loadChildren: () => import('app/modules/admin/maintenance/maintenance.routes') },

            //     // Profile
            //     {path: 'profile', loadChildren: () => import('app/modules/admin/pages/profile/profile.routes')},

            //     // Settings
            //     {path: 'settings', loadChildren: () => import('app/modules/admin/pages/settings/settings.routes')},
            // ]},

            // 404 & Catch all
            { path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/error/error-404/error-404.routes') },
            { path: '**', redirectTo: '404-not-found' }

        ]
    }
]

