import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { CoreUserService } from 'app/core/user/user.service';
import { DynamicImgSrcDirective } from 'app/shared/directives/dynamic-img-src.directive';
import { OnlineStatus, User } from 'app/shared/models/user.model';
import { Subject, takeUntil } from 'rxjs';
import { UserProfileDialogComponent } from 'app/shared/components/user-profile-dialog/profile.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector       : 'user',
    templateUrl    : './user.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'user',
    standalone     : true,
    imports        : [MatButtonModule, MatMenuModule, NgIf, MatIconModule, NgClass, MatDividerModule, DynamicImgSrcDirective],
})
export class UserComponent implements OnInit, OnDestroy
{
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user: User;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: CoreUserService,
        private _matDialog: MatDialog,
        private _breakpointObserver: BreakpointObserver
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) =>
            {
                this.user = {
                    ...this.user,
                    ...user,
                };

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    showProfile(): void {

        const isMobile = this._breakpointObserver.isMatched('(max-width: 767px)');

        this._matDialog.open(UserProfileDialogComponent, {
            disableClose: false,
            width: isMobile ? '100%' : '380px',
            height: isMobile ? '100%' : 'auto',
            maxWidth: isMobile ? '100vw' : '80vw',
            maxHeight: isMobile ? '100vh' : '80vh',
            panelClass: isMobile ? 'dialog-no-padding' : 'dialog-no-padding',
            data: {
                user: this.user,
                isCurrentUser: true,
                isMobile
            }
        })
    }

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: OnlineStatus): void
    {
        // Return if user is not available
        if ( !this.user )
        {
            return;
        }

        // Update the user
        this._userService.updateStatus({
            ...this.user,
            status,
        }).subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void
    {
        this._router.navigate(['/sign-out']);
    }
}
