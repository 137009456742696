import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { Media, MediaItems } from 'app/shared/models/media.model';

@Injectable({
    providedIn: 'root'
})
export class AwsS3Service {

    constructor(private _httpClient: HttpClient) { }

    getItems(filepath: string, filetype: string): Observable<MediaItems> {
        let params = new HttpParams();
        params = params.append('filepath', filepath);
        params = params.append('filetype', filetype);
        //  console.log(filepath);
        return this._httpClient.get<MediaItems>(`${environment.api.endpoint}/media/s3`, { params });
    }

    getItem(filepath: string): Observable<any> {
        let params = new HttpParams();
        params = params.append('filepath', filepath);
        return this._httpClient.get<any>(`${environment.api.endpoint}/media/s3/item`, { params });
    }

    upload(data: any): Observable<any> {
        return this._httpClient.post(`${environment.api.endpoint}/media/upload`, data, {
            reportProgress: true,
            observe: 'events'
        });
    }

    delete(filepath: string): Observable<any> {
        let params = new HttpParams();
        params = params.append('filepath', filepath);
        return this._httpClient.delete<any>(`${environment.api.endpoint}/media/s3/`, { params });
    }

    download(filepath: string): Observable<any> {
        let params = new HttpParams();
        params = params.append('filepath', filepath);
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/octet-stream');
        return this._httpClient.get<any>(`${environment.api.endpoint}/media/s3/download`, { headers, params });
    }

    downloadItems(filepath: string, files: Media[]): Observable<any> {
        // console.log(files);
        const data = {
            filepath,
            files,
        };
        return this._httpClient.post<any>(`${environment.api.endpoint}/media/s3/download-all`, data);
    }

    validateItems(filepath: string, files: Media[]): Observable<any> {
        // console.log(files);
        const data = {
            filepath,
            files,
        };
        return this._httpClient.post<any>(`${environment.api.endpoint}/media/s3/validate-all`, data);
    }

    createFolder(filepath: string): Observable<any> {
        return this._httpClient.post<any>(`${environment.api.endpoint}/media/s3/folder`, { filepath });
    }
}
