import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'dialog-header',
  standalone: true,
  imports: [NgIf, MatIconModule, MatButtonModule],
  templateUrl: './dialog-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DialogHeaderComponent implements OnChanges {

  @Input() title: string = '';
  @Input() subtitle: string = '';
  
  @Input() loading: boolean = false;
  @Input() showSave: boolean = true;
  
  @Input() saveBtnText: string = 'Save';
  @Input() saveBtnIcon: string = 'mat_outline:save';
  @Input() saveBtnDisabled: boolean = false;

  @Output() close = new EventEmitter<void>();
  @Output() save = new EventEmitter<void>();

  isMobile: boolean = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _fuseMediaWatcherService: FuseMediaWatcherService
  ) { }

  ngOnInit(): void {

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isMobile = !matchingAliases.includes('md');
      });

  }

  ngOnChanges(changes: SimpleChanges): void {
   // console.log(changes);
  }

  saveClick(): void {
    this.loading = true;
    this.save.emit();
  }
}
