import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { agentNavigation, compactNavigation, defaultNavigation, futuristicNavigation, guestNavigation, horizontalNavigation, userNavigation } from 'app/mock-api/common/navigation/data';

@Injectable({
    providedIn: 'root'
})
export class NavigationMockApi {
    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

    private readonly _userNavigation: FuseNavigationItem[] = userNavigation;
    private readonly _guestNavigation: FuseNavigationItem[] = guestNavigation;
    private readonly _agentNavigation: FuseNavigationItem[] = agentNavigation;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/common/navigation')
            .reply(() => {

                // Fill compact navigation children using the default navigation
                this._compactNavigation.forEach((compactNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if (defaultNavItem.id === compactNavItem.id) {
                            compactNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill futuristic navigation children using the default navigation
                this._futuristicNavigation.forEach((futuristicNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if (defaultNavItem.id === futuristicNavItem.id) {
                            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this._horizontalNavigation.forEach((horizontalNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if (defaultNavItem.id === horizontalNavItem.id) {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                return [
                    200,
                    {
                        compact: cloneDeep(this._compactNavigation),
                        default: cloneDeep(this._defaultNavigation),
                        futuristic: cloneDeep(this._futuristicNavigation),
                        horizontal: cloneDeep(this._horizontalNavigation)
                    }
                ];

                // if (!request.body.user) {
                //     // Return the response
                //     return [
                //         200,
                //         {
                //             compact: cloneDeep(this._compactNavigation),
                //             default: cloneDeep(this._defaultNavigation),
                //             futuristic: cloneDeep(this._futuristicNavigation),
                //             horizontal: cloneDeep(this._horizontalNavigation)
                //         }
                //     ];
                // }

                // const userRole = request.body.user.role;
                // switch (userRole) {
                //     case 'guest':
                //         return [
                //             200,
                //             {
                //                 compact: cloneDeep(this._guestNavigation),
                //                 default: cloneDeep(this._guestNavigation),
                //                 futuristic: cloneDeep(this._guestNavigation),
                //                 horizontal: cloneDeep(this._guestNavigation)
                //             }
                //         ];
                //     case 'user':
                //         return [
                //             200,
                //             {
                //                 compact: cloneDeep(this._userNavigation),
                //                 default: cloneDeep(this._userNavigation),
                //                 futuristic: cloneDeep(this._userNavigation),
                //                 horizontal: cloneDeep(this._userNavigation)
                //             }
                //         ];
                //     case 'agent':
                //         return [
                //             200,
                //             {
                //                 compact: cloneDeep(this._agentNavigation),
                //                 default: cloneDeep(this._agentNavigation),
                //                 futuristic: cloneDeep(this._agentNavigation),
                //                 horizontal: cloneDeep(this._agentNavigation)
                //             }
                //         ];
                //     case 'admin':
                //         return [
                //             200,
                //             {
                //                 compact: cloneDeep(this._compactNavigation),
                //                 default: cloneDeep(this._defaultNavigation),
                //                 futuristic: cloneDeep(this._futuristicNavigation),
                //                 horizontal: cloneDeep(this._horizontalNavigation)
                //             }
                //         ];

                //     default:
                //         return [
                //             200,
                //             {
                //                 compact: cloneDeep(this._compactNavigation),
                //                 default: cloneDeep(this._defaultNavigation),
                //                 futuristic: cloneDeep(this._futuristicNavigation),
                //                 horizontal: cloneDeep(this._horizontalNavigation)
                //             }
                //         ];
                // }
            });
    }
}
